import React from "react";
import { FormattedMessage } from "react-intl";
import { formatMoney } from "../../util/currency";
import { types as sdkTypes } from "../../util/sdkLoader";
import css from './ListingPage.module.css';

const SectionPackageDetailsMaybe = props => {
    const { publicData, intl } = props;
    const priceRange = publicData?.priceRange || [];
    const { Money } = sdkTypes;

    return (
        <div className={css.cancelPolicyWrapper}>
            <div className={css.cancelHeading}>
                <span className={css.cancelPolicyHeading}><FormattedMessage id='SectionDetailsMaybe.Pricelabel' /></span>
            </div>
            <div className={css.cancelDescription}>
                <ul className={css.details}>
                    {Array.isArray(priceRange) && priceRange.map((ele, index) => ele.quantityRange.split("-").length > 1 ?
                        <li key={index} className={css.detailsRow}>
                            <span className={css.detailLabel}>
                                <FormattedMessage
                                    id="SectionPackageMaybe.personsInfoText"
                                    values={{ startRange: ele?.quantityRange?.split("-")[0], endRange: ele?.quantityRange?.split("-")[1] }}
                                />
                            </span>
                            <span className={css.detailValue}>
                                <FormattedMessage
                                    id="SectionPackageMaybe.moneyFormatted"
                                    values={{money: formatMoney(intl, new Money(ele.amount, ele.currency))}}
                                />
                            </span>
                        </li>
                        :
                        <li key={index} className={css.detailsRow}>
                            <span className={css.detailLabel}>
                                <FormattedMessage id="SectionPackagemaybe.51AboveText" values={{endingRange: ele.quantityRange == "51_above" ? "+51" : null}} />
                            </span>
                            <span className={css.detailValue}>
                                <FormattedMessage
                                    id="SectionPackageMaybe.moneyFormatted"
                                    values={{money: formatMoney(intl, new Money(ele.amount, ele.currency))}}
                                />
                            </span>
                        </li>)}
                </ul>
            </div>
        </div>
    )
};

export default SectionPackageDetailsMaybe;