import React, { useState } from "react";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { ExternalLink, H1, Modal } from "../../components";
import IconCard from "../../components/SavedCardDetails/IconCard/IconCard";

import css from './ListingPage.module.css';
import { FormattedMessage } from "react-intl";
import { createResourceLocatorString } from "../../util/routes";

const SectionShareMaybeMobile = props => {
    const { listingId, intl, favourites=[], onupdateProfile, isOwnListing, isAuthenticated, history, routeConfiguration } = props;
    const isFavourite = favourites?.includes(listingId);
    const sharedURL = (typeof window !== 'undefined' && window && window.location && window.location.href);
    const [isModalOpen, setModalOpen] = useState(false);
    const [inProgress, setinProgress] = useState(false);

    const handleShareLink = async (matchingId) => {
        // Code for copylink and to share on whatsapp
        if(matchingId == "copyLink")
        {
            if(navigator.clipboard)
            {
                // Copy link only
                await navigator.clipboard.writeText(sharedURL).then(() => {
                    setModalOpen(false);
                    const successMessage = "Link coppied to clipboard";
                    enqueueSnackbar(successMessage, { autoHideDuration: 800, variant: "default" });
                }).catch(err => {
                    console.log(err);
                });
            }
            else
            {
                // Shareing for non-supported browsers
                const tempInput = document.createElement('input');
                tempInput.setAttribute('value', sharedURL);
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand('copy');
                document.body.removeChild(tempInput);
                const successMessage = "Link coppied to clipboard";
                enqueueSnackbar(successMessage, { autoHideDuration: 800, variant: "default" });
                setModalOpen(false);
            }
        }
    };

    const handleShareAddtoFavourite = async (listingId, favourites) => {
        if(isAuthenticated){
            setinProgress(true);
            const newArr = [...favourites];
            if (isFavourite) {
                const indexOfId = newArr?.indexOf(listingId);
                newArr.splice(indexOfId, 1);
            }
            else {
                newArr.push(listingId);
            }
            const profile = {
                privateData: {
                    favouriteListings: newArr
                }
            };
            await onupdateProfile(profile).then(() => {
                setinProgress(false);
            }).then(()=>{
                isFavourite ? 
                enqueueSnackbar("Removed from favourites", { autoHideDuration: 800, variant: "default" }) 
                : 
                enqueueSnackbar("Added to favourites", { autoHideDuration: 800, variant: "default" });
            });
        }
        else
        {
            history.push(createResourceLocatorString("LoginPage", routeConfiguration, {}, {}));
        }
    };

    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={500}>
            <div className={css.mobileHeading}>
                <div className={css.socialShare}>
                    <div className={css.shareButton} onClick={() => setModalOpen(true)}>
                        <IconCard brand="share" />
                        <FormattedMessage id={"SectionShareMaybe.shareLabel"} />
                    </div>
                    {!isOwnListing ? <div className={css.keepButton} onClick={() => !inProgress && handleShareAddtoFavourite(listingId, favourites)}>
                        {isFavourite ?
                            <div>
                                <span><IconCard brand="like" /></span>
                                <FormattedMessage id={"SectionShareMaybe.FavouriteLabel"} /> 
                            </div> :
                            <div>
                                <span className={css.dislike}><IconCard brand="dislike" /></span>
                                &nbsp;
                                <FormattedMessage id={"SectionShareMaybe.AddtoFavouriteLabel"} /> 
                            </div>
                        }
                    </div> : null}
                </div>
                <Modal
                    id={"sharingModal"}
                    isOpen={isModalOpen}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                    onManageDisableScrolling={() => { }}
                    className={css.sharingModal}
                >
                    <div className={css.sharingBox}>
                        <H1><FormattedMessage id={"SectionShareMaybe.sharingOptionsLabel"} /></H1>
                        <div className={css.shareButton} onClick={() => handleShareLink("copyLink")}>
                            <IconCard brand="copy" /> &nbsp;
                            <FormattedMessage id={"SectionShareMaybe.copyLinkLabel"} />
                        </div>
                        {/* Whatsapp */}
                        <ExternalLink className={css.shareButton} href={`whatsapp://send?text=${sharedURL}`}>
                            <IconCard brand={"whatsapp"} /> &nbsp;
                            <FormattedMessage id={"SectionShareMaybe.sharetoWhatsappLabel"} /> 
                        </ExternalLink>
                        {/* <div className={css.shareButton} onClick={() => handleShareLink("openWhatsapp")}>
                            <IconCard brand={"whatsapp"} /> &nbsp; Share to Whatsapp
                        </div> */}
                    </div>
                </Modal>
            </div>
        </SnackbarProvider>
    )
};

export default SectionShareMaybeMobile;