import React from "react";
import { ListingCard } from "../../components";
import { getListingsById } from "../../ducks/marketplaceData.duck";
import { FormattedMessage } from "react-intl";
import css from './ListingPage.module.css';
import Slider from "react-slick";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="30px" height="30px" viewBox="0 0 0.75 0.75" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" fillRule="evenodd" d="m0.264 0.081 0.272 0.272a0.025 0.025 0 0 1 0.008 0.018 0.026 0.026 0 0 1 -0.008 0.019c-0.098 0.096 -0.193 0.188 -0.284 0.278 -0.005 0.004 -0.023 0.015 -0.038 -0.001 -0.014 -0.016 -0.006 -0.03 0 -0.036l0.265 -0.259 -0.253 -0.253c-0.009 -0.013 -0.008 -0.024 0.002 -0.035 0.011 -0.011 0.023 -0.011 0.036 -0.002Z" /></svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="30px" height="30px" viewBox="0 0 0.75 0.75" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" fillRule="evenodd" d="M0.271 0.371c0.086 -0.087 0.171 -0.171 0.253 -0.253a0.024 0.024 0 0 0 0 -0.034c-0.012 -0.013 -0.03 -0.012 -0.039 -0.003 -0.085 0.086 -0.175 0.175 -0.268 0.268 -0.007 0.006 -0.011 0.013 -0.011 0.022 0 0.008 0.004 0.016 0.011 0.022l0.281 0.274a0.028 0.028 0 0 0 0.039 -0.001c0.013 -0.013 0.008 -0.027 0.002 -0.033a127.79 127.79 0 0 1 -0.269 -0.262Z" /></svg>
    </div>
  );
}

const SimilarListingMaybe = props => {
  const {
    listings,
    currentListingId,
    setActiveListing,
    isMapVariant,
    intl,
    currentUser,
    isAuthenticated,
    onupdateProfile,
    history,
    onFetchReviews,
    reviews = []
  } = props;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const settings = {
    speed: 500,
    dots: false,
    arrows: true,
    slidesToShow: 2.2,
    centerMode: true,
    initialSlide: 0,
    infinite: true,
    draggable: false,
    // slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: false
        }
      },
    ]
  };


  return (
    <div>
      {listings?.length > 1 ?
        <div className={css.similarHeading}>
          <h2 className={css.similarTitle}>
            <FormattedMessage id="SimilarListingsPage.mainHeading" />
          </h2>
          <div className={listings <= 3 ? css.similarCardGrid : css.similarCardSlider}>
            {listings.length <= 3 ?
              <div className={css.withoutSlider}>
                {listings?.filter(l => l.id.uuid != currentListingId.uuid)?.map((l, index) => {
                  return (
                    <div key={index}>
                      <ListingCard
                        className={css.listingCard}
                        key={l.id.uuid}
                        listing={l}
                        renderSizes={cardRenderSizes(isMapVariant)}
                        setActiveListing={setActiveListing}
                        listingIds={listings.map(l => l.id.uuid)}
                        currentUser={currentUser}
                        listingId={l.id.uuid}
                        isAuthenticated={isAuthenticated}
                        onupdateProfile={onupdateProfile}
                        history={history}
                        reviews={l?.attributes?.publicData?.totalReviews ?? 0}
                        onFetchReviews={onFetchReviews}
                      />
                    </div>
                  )
                }
                )}
              </div> :
              <Slider {...settings}>
                {listings.filter(l => l.id.uuid != currentListingId.uuid).map((l, index) => (
                  <div key={index}>
                    <ListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes(isMapVariant)}
                      setActiveListing={setActiveListing}
                      listingIds={listings.map(l => l.id.uuid)}
                      currentUser={currentUser}
                      listingId={l.id.uuid}
                      isAuthenticated={isAuthenticated}
                      onupdateProfile={onupdateProfile}
                      history={history}
                      reviews={l?.attributes?.publicData?.noReviews ?? 0}
                      onFetchReviews={onFetchReviews}
                    />
                  </div>
                ))}
              </Slider>
            }
          </div>
        </div>
        : null}
    </div>
  )
};

export default SimilarListingMaybe;