import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import IconCard from '../../components/SavedCardDetails/IconCard/IconCard';
import css from './ListingPage.module.css';

const SectionCancelPolicy = props => {
    const { publicData, metadata = {}, listingConfig, intl } = props;

    return (
        <div className={css.cancelPolicyWrapper}>
            <div className={css.cancelHeading}>
                {/* <span className={css.cancelIcon}>
                    <IconCard brand="cancel" />
                </span> */}
                <span className={css.cancelPolicyHeading}>
                    <FormattedMessage id='SectionCancelPolicy.cancellationTitle' />
                </span>
            </div>
            <div className={css.cancelDescription}>
                <FormattedMessage id='SectionCancelPolicy.cancellationDescription1' />
                <br/>
                <br/>
                <FormattedMessage id='SectionCancelPolicy.cancellationDescription2' />
            </div>
        </div>
    )
};

export default SectionCancelPolicy;
