import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
} from '../../components';
import IconCard from '../../components/SavedCardDetails/IconCard/IconCard.js';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  showListing,
  showListingById,
  saveListingsfromSearchPage,
  queryListingbyIds,
  onFetchReviews,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';

// Sections
import SectionHero from './SectionHero';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionCancelPolicy from './SectionCancelPolicy.js';
import SectionHowitWork from './SectionHowitWork.js';
import SimilarListingMaybe from './SimilarListingMaybe.js';
import SectionPackageDetailsMaybe from './SectionPackageMaybe.js';
import SectionShareMaybe from './SectionShareMaybe.js';

// ducks
import { setActiveListing } from '../SearchPage/SearchPage.duck.js';

import css from './ListingPage.module.css';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck.js';
import SectionShareMaybeMobile from './SectionShareMaybeMobile.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const SESSION_KEY = "listingIds";

const { UUID, Money } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onActivateListing,
    getListingById,
    queryListing,
    searchPageListings,
    onupdateProfile,
    reviewsById,
    onFetchReviews,
    similarListingsMaybe
  } = props;

  // useEffect(() => {
  //   //similar listing logic change
  //   // need to change it later once yugam comes back
  //   const listingIds = (typeof window !== 'undefined' && window && JSON.parse(window.sessionStorage.getItem(SESSION_KEY)));
  //   if(listingIds && listingIds.length) {
  //   const random = Math.floor(Math.random() * listingIds.length);
  //   const random2 = Math.floor(Math.random() * listingIds.length);
  //   const random3 = Math.floor(Math.random() * listingIds.length);
  //   const random4 = Math.floor(Math.random() * listingIds.length);
  //   const similarListings = listingIds && listingIds.length?[listingIds[random],listingIds[random2],listingIds[random3],listingIds[random4]]:[];
  //   Array.isArray(similarListings) && similarListings.length && queryListing(similarListings, config);
  //   }
  // }, [location]);



  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  // const listings = currentListing?.id?.uuid && searchedListings.filter(id => id != currentListing?.id?.uuid).map(id => getListingById(new UUID(id), config, false));
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const { priceRange = [], location: listingLocation, maxGuest, minGuest } = publicData || {};
  // const minGuestRange = priceRange && Array.isArray(priceRange) && priceRange.length && priceRange[0].quantityRange.split("-");
  // const minGuest = minGuestRange?.length > 1 && minGuestRange[0];
  // const maxGuestRange = priceRange && Array.isArray(priceRange) && priceRange.length && priceRange[priceRange.length - 1].quantityRange.split("-");
  // const maxGuest = maxGuestRange?.length > 1 ? maxGuestRange[1] : maxGuestRange[0];
  const startWithPrice = price;
  const locationArray = listingLocation?.address.split(", ");
  const poolAddress = locationArray ? `${locationArray[1]}, ${locationArray[2]}` : "";

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(startWithPrice, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  // const onContactUser = (user, dataObj) =>{
  //   handleContactUser({
  //     ...commonParams,
  //     currentUser,
  //     callSetInitialValues,
  //     location,
  //     setInitialValues,
  //     setInquiryModalOpen,
  //     bookingData: dataObj,
  //     user
  //   });
  // }
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = startWithPrice
    ? {
      price: intl.formatNumber(convertMoneyToNumber(startWithPrice), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: startWithPrice.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const handleViewPhotosClick = e => {
    // Stop event from bubbling up to prevent image click handler
    // trying to open the carousel as well.
    e.stopPropagation();
    setImageCarouselOpen(true);
  };

  const ensuredUser = ensureUser(currentUser);
  const { favouriteListings } = ensuredUser && ensuredUser?.id && ensuredUser?.attributes?.profile?.privateData || {};


  //Rating work
  const totalRatings = reviews.reduce((sum, review) => sum + review?.attributes?.rating, 0);
  const reviewLength = reviews?.length ?? 0;
  const averageRating = (totalRatings != 0 && reviewLength != 0 ? (totalRatings / reviewLength) : 0);
  const descriptionheading = intl.formatMessage({ id: "ListingPageCoverPhoto.descriptionHeading" });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <SectionHero
          title={title}
          listing={currentListing}
          isOwnListing={isOwnListing}
          editParams={{
            id: listingId.uuid,
            slug: listingSlug,
            type: listingPathParamType,
            tab: listingTab,
          }}
          imageCarouselOpen={imageCarouselOpen}
          onImageCarouselClose={() =>{
            setImageCarouselOpen(false);
            setTimeout(() => {
              typeof window !== "undefined" && window.scrollTo({ top: 0 });
            }, 300);
          }}
          handleViewPhotosClick={handleViewPhotosClick}
          onManageDisableScrolling={onManageDisableScrolling}
        />
        <div className={css.contentWrapperForHeroLayout}>
          <div className={css.mainColumnForHeroLayout}>
            {/* Here Mobile Social Share */}
            <SectionShareMaybeMobile
              intl={intl}
              listingId={listingId?.uuid}
              favourites={favouriteListings}
              onupdateProfile={onupdateProfile}
              isOwnListing={isOwnListing}
              isAuthenticated={isAuthenticated}
              history={history}
              routeConfiguration={routeConfiguration}
            />
            <div className={css.mobileHeading}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            </div>

            {/* Favourite-Share */}
            <SectionShareMaybe
              intl={intl}
              listingId={listingId?.uuid}
              favourites={favouriteListings}
              onupdateProfile={onupdateProfile}
              isOwnListing={isOwnListing}
              isAuthenticated={isAuthenticated}
              history={history}
              routeConfiguration={routeConfiguration}
            />

            <div className={css.topSectionBar}>
              <div className={css.locationSection}>
                <span className={css.locationIcon}>
                  <IconCard brand="locationcard" />
                </span>
                <span className={css.locationAddress}>
                  {/* 08348 Cabrils, Barcelona, Spain */}
                  {poolAddress}
                </span>
              </div>
              <div className={css.locationSection}>
                <span className={css.locationIcon}>
                  <IconCard brand="user" />
                </span>
                <span className={css.locationAddress}>
                  {maxGuest == '51_above' ? '+51' : maxGuest} <FormattedMessage id="ListingPageCoverPhoto.maxGuestLabel" />
                </span>
              </div>
              <div className={css.locationSection}>
                {averageRating == 0 ? <IconCard brand="star" /> : null}
                <span className={css.locationIcon}>
                  {Array.from({ length: Math.floor(averageRating) }, (_, index) => (
                    <IconCard key={index} brand="star" />
                  ))}
                  {averageRating % 1 !== 0 && <IconCard brand="halfstar" />}
                </span>
                <span className={css.locationAddress}>({reviews?.length} <FormattedMessage id="ListingPageCoverPhoto.reviewslabel" />)</span>
              </div>
            </div>

            <SectionTextMaybe text={description} showAsIngress heading={descriptionheading} />

            {listingConfig.listingFields
              .filter(item => item.key != 'capacity')
              .reduce((pickedElements, config) => {
                const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
                const listingType = publicData?.listingType;
                const isTargetListingType =
                  includeForListingTypes == null || includeForListingTypes.includes(listingType);

                const value =
                  scope === 'public'
                    ? publicData[key]
                    : scope === 'metadata'
                      ? metadata[key]
                      : null;
                const hasValue = value != null;
                return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value || []}
                      keyName={key}
                    />,
                  ]
                  : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                    ? [
                      ...pickedElements,
                      <SectionTextMaybe
                        key={key}
                        heading={config?.showConfig?.label}
                        text={value}
                      />,
                    ]
                    : pickedElements;
              }, [])}

            <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
              price={formattedPrice}
            />

            {formattedPrice != undefined ? <SectionPackageDetailsMaybe publicData={publicData} intl={intl} /> : null}

            <SectionCancelPolicy />

            <SectionHowitWork />

            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            />

            <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />

            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />

            <div className={css.similarListingCard}>
            <SimilarListingMaybe
                listings={similarListingsMaybe}
                currentListingId={listingId}
                setActiveListing={onActivateListing}
                intl={intl}
                currentUser={currentUser}
                isAuthenticated={isAuthenticated}
                onupdateProfile={onupdateProfile}
                history={history}
                reviews={reviewsById}
                onFetchReviews={onFetchReviews}
                isMapVariant
              />
            </div>

          </div>
          <div className={css.orderColumnForHeroLayout}>
            <OrderPanel
              className={css.orderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              startWithPrice={startWithPrice}
              onSubmitInquiry={onSubmitInquiry}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              isAuthenticated={isAuthenticated}
            />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    savedListings,
    similarListingsSucess
  } = state.ListingPage;
  
  const similarListingsMaybe = getListingsById(state, similarListingsSucess);

  const { currentUser } = state.user;
  const { currentPageResultIds, reviews: reviewsById } = state.SearchPage;
  currentPageResultIds.length > 0 ? window && window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(currentPageResultIds)) : null;
  const searchPageListings = savedListings;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    searchPageListings,
    reviewsById,
    similarListingsMaybe
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, protectedData, message) => dispatch(sendInquiry(listing, protectedData, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  getListingById: (listingId, config) => dispatch(showListingById(listingId, config)),
  queryListing: (listingIds, config) => dispatch(queryListingbyIds(listingIds, config)),
  onupdateProfile: (data) => dispatch(updateProfile(data)),
  onFetchReviews: (listingId) => dispatch(onFetchReviews(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
